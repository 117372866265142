* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; /* Set background to black */
  color: white; /* Set default text color to white */
}

body {
  font-family: Arial, sans-serif;
}

.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.start-button {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #007bff;
  color: white; /* Ensure button text stays white */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.start-button:hover {
  background-color: #0056b3;
}
